<template>
  <div>
    <div class="naxbar">
      <div class="company">
        <img src="~@/assets/company.png" alt="" class="logo" />
        <h3>广州钱柜物联科技有限公司</h3>
      </div>
    </div>
    <div class="container">
      <div class="detail-head-card">
        <div class="con-l">
          <img src="~@/assets/logo.png" alt="" class="logo" />
          <div class="intro">
            <h3>ix cam</h3>
            <p>
              ix cam APP是由广州钱柜物联科技有限公司自主研发、生成的监控视频产品配套进行配网使用的APP。
            </p>
          </div>
        </div>
        <div class="download">
          <el-button type="primary" round @click="download"
            >下载（安卓）</el-button
          >
        </div>
      </div>

      <el-divider></el-divider>

      <div class="img-intro-box">
        <img src="~@/assets/index1.png" alt="" class="intro" />
        <img src="~@/assets/index2.png" alt="" class="intro" />
        <img src="~@/assets/index3.png" alt="" class="intro" />
        <img src="~@/assets/index4.png" alt="" class="intro" />
      </div>

      <el-divider></el-divider>

      <div class="app-intro">
        <h3>应用介绍</h3>
        <p class="intro">
          ix cam是一款家庭安全监控应用，帮助连接和管理网络摄像机，支持查看实时视频，回放录像，云端存储，语音对讲与AI人形检测等功能。
        </p>
      </div>

      <el-divider></el-divider>

      <div class="pcappinfocard">
        <h3 class="title">应用信息</h3>
        <div class="flex-box">
          <div class="item">
            <span>资费</span>
            <span>免费</span>
          </div>
          <div class="item">
            <span>应用分级</span>
            <span>年满4岁+</span>
          </div>
          <div class="item">
            <span>开发者</span>
            <span>广州钱柜物联科技有限公司</span>
          </div>
          <a
            class="item link"
            href="http://ixcam.naxclow.cn/user_agreement/#/userProtocol"
            target="_blank"
          >
            <span>用户协议</span>
            <span class="el-icon-arrow-right"></span>
          </a>
          <div class="item">
            <span>版本</span>
            <span>1.0.0</span>
          </div>
          <a
            class="item link"
            href="http://ixcam.naxclow.cn/user_agreement/#/privacy"
            target="_blank"
          >
            <span>隐私政策</span>
            <span class="el-icon-arrow-right"></span>
          </a>
          <div class="item">
            <span>技术支持:</span>
            <span>广州钱柜物联科技有限公司</span>
          </div>
          <div class="item link">
            <span>联系方式</span>
            <p class="item_email">
              <span>zengsihong@naxclow.cn</span>
              <span class="el-icon-arrow-right"></span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <footer>
      <p>
        广州钱柜物联科技有限公司
        <a href="https://beian.miit.gov.cn/#/Integrated/index"
          >粤ICP备18126026号</a
        >
      </p>

      <p>Copyright 2018 - 2021 Naxclow. All Rights Reserved</p>
    </footer>
  </div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {};
  },
  methods: {
    download() {
      window.location.href = "https://dl.naxclow.com/api/d/da8d0a0626e1e1ec43004d8523bee257";
    },
  },
};
</script>

<style lang="less" scoped>
.naxbar {
  border-bottom: 1px solid #f2f2f2;
  // height: 70px;
  padding: 20px 40px;
  .company {
    display: flex;
    align-items: center;
    .logo {
      height: 50px;
    }
    h3 {
      margin-left: 20px;
    }
  }
}
.container {
  width: 1000px;
  margin: 20px auto;
  padding-bottom: 100px;
  .detail-head-card {
    // height: 112px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .con-l {
      display: flex;
      .logo {
        width: 120px;
        height: 120px;
        display: block;
        border-radius: 20px;
      }
      .intro {
        margin-left: 20px;
        p {
          margin-top: 20px;
        }
      }
    }
  }
  .img-intro-box {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .intro {
      width: 300px;
      border: 1px solid #f2f2f2;
    }
  }
  .app-intro {
    .intro {
      text-indent: 2em;
      margin: 10px 0;
    }
  }
  .pcappinfocard {
    .title {
      margin-bottom: 10px;
    }
    .flex-box {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .item {
        width: 45%;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #f2f2f2;
        padding: 10px 0;
        color: #666;
        &.link {
          &:hover {
            cursor: pointer;
          }
          .item_email {
            color: #236eff;
          }
        }
      }
    }
  }
}
footer {
  background-color: #f2f2f2;
  padding: 30px 0;
  text-align: center;
  p {
    margin: 10px 0;
  }
}
</style>